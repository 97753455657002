import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { MessageIcon } from '../icons'

const InfoBox = dynamic(() => import('../components/Boxes/InfoBox'))

interface Props {
  title: string
  description: string
  action: string
  actionTitle: string
  href?: string
}

const InfoBoxContainer: FC<Props> = ({ title, description, action, actionTitle, href }) => (
  <InfoBox
    title={title}
    description={description}
    action={action}
    actionTitle={actionTitle}
    href={href || '/page-here'}
    icon={<MessageIcon />}
  />
)

export default InfoBoxContainer
